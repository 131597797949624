import React from 'react';
import {
  Content,
  Container,
  MainInformation,
  DataContainer,
  ImageContainer,
  GoalInformation,
  Text,
} from 'app/components/matchEvents/MatchEventStyles';
import {COLORS, Image, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {Person} from '@mui/icons-material';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';
import MatchEventMinute from 'app/components/matchEvents/MatchEventMinute';

const MatchEventPenaltyFail = ({event}) => {
  const isDeactivated = event.primaryRole?.player?.isDeactivated;

  const checkImage =
    event.primaryRole && event.primaryRole.player.image ? (
      <Image entity='player' src={event.primaryRole.player.image} ratio='1:1' width={64} height={64} circle={true} />
    ) : (
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    );
  let standingMessage = 'Elfmeter verschossen ❌';
  standingMessage += event.team ? ` von ${event.team.name.middle}` : '';
  const playerName =
    event.primaryRole && event.primaryRole.player
      ? event.primaryRole.player.firstName + ' ' + event.primaryRole.player.lastName
      : 'Nicht angegeben';
  const time = event.additionalMinute > 0 ? `${event.minute}'+${event.additionalMinute}` : `${event.minute}'`;

  const penaltyType = subtype =>
    ({
      penalty_foul: (
        <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
          Foulelfmeter
        </ProfileTypography>
      ),
      penalty_hand: (
        <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
          Handelfmeter
        </ProfileTypography>
      ),
      penalty_shootout: (
        <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
          Elfmeterschießen
        </ProfileTypography>
      ),
    }[subtype]);

  const displayPenaltyType = penaltyType(event.subtype);

  let assist, text;
  if (event.secondaryRole) {
    const seasonCount = event.secondaryRole.seasonCount
      ? `(${event.secondaryRole.seasonCount}. gehaltener Elfmeter)`
      : null;
    assist = event.secondaryRole.player ? (
      <Typography variant='caption1'>
        Torwart: {event.secondaryRole.player.firstName} {event.secondaryRole.player.lastName} {seasonCount}
      </Typography>
    ) : null;
  }
  if (event.text) {
    text = (
      <Text withPadding variant='body2'>
        {event.text}
      </Text>
    );
  }

  const mainInfo = (
    <MainInformation>
      <ImageContainer>{checkImage}</ImageContainer>
      <GoalInformation>
        <Typography variant='body1'>{standingMessage}</Typography>
        <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
          {playerName}
        </ProfileTypography>
        {displayPenaltyType}
      </GoalInformation>
    </MainInformation>
  );

  const eventContent =
    event.primaryRole && event.primaryRole.player && !isDeactivated ? (
      <LinkOnComponent to={`/player/${event.primaryRole.player.slug}`}>{mainInfo}</LinkOnComponent>
    ) : (
      mainInfo
    );

  return (
    <Content>
      <Container>
        <MatchEventMinute minute={time} />
        <DataContainer>
          {eventContent}
          {assist}
          {text}
        </DataContainer>
      </Container>
    </Content>
  );
};

export default MatchEventPenaltyFail;

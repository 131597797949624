import {CreateResultResponse, StoreResultResponse} from 'app/components/match/liveResult/types';
import {InitScorerFormData} from 'app/components/match/liveResult/updateScorer/sharedTypes';

export enum SoftTickerState {
  Result = 'RESULT',
  ResultSuccess = 'RESULT_SUCCESS',
  ScorerAdd = 'ADD_SCORER',
  ScorerUpdate = 'UPDATE_SCORER',
}

export interface SoftTickerData {
  open: boolean;
  state: SoftTickerState;
  createResult: CreateResultResponse | null;
  resultData: StoreResultResponse | null;
  scorerFormData: InitScorerFormData | null;
  matchEventId: number | null;
  initiatorView: string | null;
}

export const initialSoftTickerData: SoftTickerData = {
  open: false,
  state: SoftTickerState.Result,
  createResult: null,
  resultData: null,
  scorerFormData: null,
  matchEventId: null,
  initiatorView: null,
};

export type UpdateScorerData = {scorerData: InitScorerFormData; id: number};

export type SoftTickerAction =
  | {type: 'OPEN_RESULT_FORM'; data: CreateResultResponse | null}
  | {type: 'UPDATE_RESULT_SUCCESS'; data: StoreResultResponse | null}
  | {type: 'OPEN_ADD_SCORER'; data: string}
  | {type: 'OPEN_UPDATE_SCORER'; data: UpdateScorerData}
  | {type: 'LOAD_SOFT_TICKER_DATA'; data: SoftTickerData}
  | {type: 'CLOSE_SOFT_TICKER_DIALOG'};

export default function softTickerReducer(softTickerData: SoftTickerData, action: SoftTickerAction): SoftTickerData {
  switch (action.type) {
    case 'OPEN_UPDATE_SCORER': {
      return {
        ...initialSoftTickerData,
        open: true,
        state: SoftTickerState.ScorerUpdate,
        scorerFormData: action.data.scorerData,
        matchEventId: action.data.id,
      };
    }
    case 'OPEN_ADD_SCORER': {
      const defaultData = action.data === 'layer_report_scorer' ? softTickerData : initialSoftTickerData;
      return {...defaultData, state: SoftTickerState.ScorerAdd, open: true, initiatorView: action.data};
    }
    case 'UPDATE_RESULT_SUCCESS': {
      return {...softTickerData, state: SoftTickerState.ResultSuccess, resultData: action.data};
    }
    case 'OPEN_RESULT_FORM': {
      return {...initialSoftTickerData, open: true, state: SoftTickerState.Result, createResult: action.data};
    }
    case 'CLOSE_SOFT_TICKER_DIALOG': {
      return {...initialSoftTickerData, state: softTickerData.state, open: false};
    }
    case 'LOAD_SOFT_TICKER_DATA': {
      return action.data;
    }
    default: {
      throw Error('(SoftTickerReducer:) Unknown action');
    }
  }
}

import React from 'react';
import {FlexContainer} from 'app/styles/content';
import {
  Content,
  Container,
  DataContainer,
  ImageContainer,
  GoalInformation,
} from 'app/components/matchEvents/MatchEventStyles';
import {COLORS, Image, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {Person} from '@mui/icons-material';
import {Card, TwoCards} from 'app/components/icons';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';
import MatchEventMinute from 'app/components/matchEvents/MatchEventMinute';
import styled from 'styled-components';

const TypographyWithIcon = styled(Typography)`
  &&& {
    display: flex;
    align-items: center;
    svg {
      margin: 0 0.125rem;
    }
  }
`;

const MatchEventCard = ({event}) => {
  if (!event.primaryRole) {
    return null;
  }
  const isDeactivated = event.primaryRole?.player?.isDeactivated;

  const checkImage =
    event.primaryRole && event.primaryRole.player.image ? (
      <Image entity='player' src={event.primaryRole.player.image} ratio='1:1' width={64} height={64} circle={true} />
    ) : (
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    );
  const playerName = event.primaryRole
    ? event.primaryRole.player.firstName + ' ' + event.primaryRole.player.lastName
    : 'Spieler nicht angegeben';
  const time = event.additionalMinute > 0 ? `${event.minute}'+${event.additionalMinute}` : `${event.minute}'`;

  const getCardObj = subtype =>
    ({
      card_yellow: {
        icon: <Card style={{color: COLORS.yellow, fontSize: 16}} />,
        cardType: 'Gelb',
        text: event.primaryRole.seasonCount ? `(${event.primaryRole.seasonCount}. Gelbe Karte)` : null,
      },
      card_yellow_red: {
        icon: <TwoCards style={{fontSize: 16}} />,
        cardType: 'Gelb-Rot',
        text: event.primaryRole.seasonCount ? `(${event.primaryRole.seasonCount}. Gelb-Rote Karte)` : null,
      },
      card_red: {
        icon: <Card style={{color: COLORS.red, fontSize: 16}} />,
        cardType: 'Rot',
        text: event.primaryRole.seasonCount ? `(${event.primaryRole.seasonCount}. Rote Karte)` : null,
      },
    }[subtype]);

  const cardObj = getCardObj(event.subtype);

  const highlightColor = event.subtype !== 'card_yellow' ? COLORS.red : null;

  const standingMessage = (
    <>
      {cardObj.cardType} {cardObj.icon} für {event.team?.name?.middle ?? 'unbekanntes Team'}
    </>
  );

  const cardContent = (
    <FlexContainer>
      <ImageContainer>{checkImage}</ImageContainer>
      <GoalInformation>
        <TypographyWithIcon variant='body1'>{standingMessage}</TypographyWithIcon>
        <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
          {playerName}
        </ProfileTypography>
        <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
          {event.text} {cardObj.text}
        </ProfileTypography>
      </GoalInformation>
    </FlexContainer>
  );

  const content = !isDeactivated ? (
    <LinkOnComponent to={`/player/${event.primaryRole.player.slug}`}>{cardContent}</LinkOnComponent>
  ) : (
    cardContent
  );

  const customStyle = highlightColor ? {borderLeft: `1px solid ${highlightColor}`} : {};
  return (
    <Content>
      <Container>
        <MatchEventMinute customBgColor={highlightColor} minute={time} />
        <DataContainer style={customStyle}>{content}</DataContainer>
      </Container>
    </Content>
  );
};

export default MatchEventCard;

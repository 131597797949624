import styled, {css} from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';

const Content = styled(FlexContainer)`
  flex-direction: column;
`;

const Container = styled(FlexContainer)`
  padding: 0.5rem 1rem 0.75rem 1rem;
  flex-direction: column;
  ${props =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0;
    `}
`;

const EventInformation = styled(FlexContainer)`
  align-self: start;
  flex-direction: column;
  align-items: center;
  min-width: 3rem;
  padding: 0.25rem;
  background: ${props => (props.customBgColor ? props.customBgColor : COLORS.lightGrey)};
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
`;

const MainInformation = styled(FlexContainer)`
  padding-bottom: ${SIZES['8']};
`;

const Minute = styled(Typography)`
  line-height: ${SIZES['24']};
`;

const DataContainer = styled(FlexContainer)`
  flex: 1 75%;
  flex-direction: column;
  padding-left: ${SIZES['16']};
  padding-bottom: 1rem;
  padding-top: 0.25rem;
  border-left: 1px solid ${COLORS.lightGrey};
`;

const ImageContainer = styled.div`
  margin-right: ${SIZES['8']};
  height: ${SIZES[64]};
`;

const GoalInformation = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
`;

const Text = styled(Typography)`
  &&& {
    line-height: 1.6;
    overflow-wrap: anywhere;
  }
  ${props =>
    props.withPadding &&
    css`
      padding-top: ${SIZES['8']};
    `}
`;

const MediaWrapper = styled(FlexContainer)`
  padding-top: ${props => (props.nopadding ? 0 : '0.25rem')};
  max-width: 98%;
  justify-content: space-between;
`;

const MediaImageWrapper = styled.div`
  margin-left: ${SIZES['8']};
  overflow: hidden;
  border-radius: ${SIZES['4']};
  width: ${SIZES['64']};
  height: ${SIZES['64']};
`;

const MediaTextWrapper = styled.div`
  flex: 1;
`;

export {
  Content,
  Container,
  EventInformation,
  MainInformation,
  Minute,
  DataContainer,
  ImageContainer,
  GoalInformation,
  Text,
  MediaWrapper,
  MediaImageWrapper,
  MediaTextWrapper,
};

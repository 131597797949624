import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography, Placeholder, FONTS} from '@fupa/fupa-uikit';

const StreamContentContainer = styled.div`
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  flex: 0 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  flex-flow: column wrap;
`;

const FullStreamContentContainer = styled(StreamContentContainer)`
  &&& {
    height: 56.25vw;
    width: 100%;
    overflow: hidden;
    padding: 0;
    max-width: ${props => props.theme.mobileContentWidth}px;
    max-height: ${props => (props.theme.mobileContentWidth * 9) / 16}px;

    ${props => props.theme.desktopLayout`
      max-width: ${props => props.theme.elementsWidth2Columns}px;
      max-height: ${props => (props.theme.elementsWidth2Columns * 9) / 16}px;
    `}
  }
`;

const StreamEntity = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  vertical-align: middle;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
`;

const LeftAlignedStreamEntity = styled(StreamEntity)`
  &&& {
    width: 100%;
    justify-content: flex-start;
  }
`;

// Start ContentTitle
const TitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: ${SIZES['16']} ${SIZES['16']} ${SIZES['24']};
  // mobile / default font size
  min-height: ${props => (props.oneLine ? '4.75rem' : SIZES[112])};
  ${props => props.theme.streamLayoutOneColBig`
    min-height: ${props => (props.oneLine ? '4.375rem' : '6.25rem')};
  `}
`;

const StyledTypography = styled(Typography)`
  &&& {
    line-height: 1.5;
    ${props => props.theme.streamLayoutOneColBig`
      font-size: ${FONTS[24]};
    `}
  }
`;

const ContentTitle = ({className, children, oneLine}) => {
  return (
    <TitleWrapper oneLine={oneLine} className={className}>
      <StyledTypography variant='title2'>{children}</StyledTypography>
    </TitleWrapper>
  );
};
// End ContentTitle

const EntityName = styled.div`
  color: ${COLORS.darkestGrey};
  line-height: ${SIZES['24']};
  ${props =>
    props.isDeactivated &&
    `
    color: ${COLORS.darkGrey};
  `}
`;

const ImageContainer = styled.div`
  height: ${SIZES[64]};
  position: relative;
`;

const ToTeamAlt = styled.div`
  display: flex;
  max-width: 4rem;
  min-height: 4rem;
  justify-content: center;
`;

const StreamIcon = styled.div`
  display: inline-flex;
  width: ${SIZES['28']};
  height: ${SIZES['28']};
  border-radius: 50%;
  background-color: ${props => props.color};
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const StreamPlaceholder = styled(Placeholder)`
  align-items: center;
  height: ${SIZES['48']};
  ${props => props.theme.desktopLayout`
      box-sizing: border-box;
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey}
   `}
`;

const Header = styled(Typography)`
  display: flex;
  flex-direction: column;
  min-height: ${SIZES[52]};
`;

const Container = styled.div`
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  flex: 0 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SIZES['8']} ${SIZES['16']};
  flex-flow: row wrap;
`;

const Category = styled.div`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
`;

const CategoryName = styled.div`
  line-height: ${SIZES[18]};
  color: ${COLORS.darkerGrey};
`;

const MessageHeading = styled.div`
  line-height: ${SIZES[18]};
  color: ${COLORS.darkestGrey};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Timestamp = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: ${SIZES['64']};
  padding-left: ${SIZES['8']};
  color: ${COLORS.darkerGrey};
  line-height: ${SIZES[18]};
`;

const Line = styled.span`
  margin: 0 ${SIZES['16']};
  width: auto;
  border-bottom: ${SIZES['1']} solid ${COLORS.lighterGrey};
`;

const InfoColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding-left: ${SIZES['8']};
`;

const LoadingWrapper = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
  max-width: ${props => props.theme.mobileContentWidth}px;

  ${props =>
    props.theme.desktopLayout`
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey};
      max-width:  ${props.theme.desktopContentWidth}px;
    `}
`;

const StreamWrapper = styled.div`
  margin-bottom: 0.5rem;
  background-color: ${COLORS.white};
  display: block;
  width: 100%;
  max-width: ${props => props.theme.mobileContentWidth}px;

  ${props =>
    props.theme.desktopLayout`
      height: fit-content;
      box-sizing: content-box;
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey};
      max-width: ${props => (props.heroLayout ? props.theme.desktopContentWidth : props.theme.elementsWidth2Columns)}px;
    `}
`;

const MultilineInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${SIZES['16']};
`;

export {
  StreamContentContainer,
  FullStreamContentContainer,
  StreamEntity,
  LeftAlignedStreamEntity,
  ContentTitle,
  EntityName,
  ImageContainer,
  ToTeamAlt,
  StreamIcon,
  StreamPlaceholder,
  Header,
  Container,
  Category,
  CategoryName,
  MessageHeading,
  Timestamp,
  Line,
  InfoColumn,
  LoadingWrapper,
  StreamWrapper,
  MultilineInfo,
};

import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {reduxSelectCurrentUser} from 'app/hooks/reduxSelectCurrentUser';

const checkIsReportable = (reportableAt: string | null): boolean => {
  if (!reportableAt) {
    return false;
  }
  const reportableAtDate = new Date(reportableAt).getTime();
  const now = new Date().getTime();
  return reportableAtDate <= now;
};

const checkIsReportableUntil = (reportableUntil: string | null): boolean => {
  if (!reportableUntil) {
    return false;
  }
  const reportableUntilDate = new Date(reportableUntil).getTime();
  const now = new Date().getTime();
  return reportableUntilDate >= now;
};

interface SofttickerMatch {
  flags: string[] | null;
  softtickerResultReportableAt: string | null;
  softtickerEventsReportableUntil: string | null;
}

export const useIsSofttickerEligible = (match: SofttickerMatch): boolean => {
  return useMemo(() => {
    const hasTicker = !!match.flags?.includes('ticker');
    const isReportable = checkIsReportable(match.softtickerResultReportableAt);
    return isReportable && !hasTicker;
  }, [match]);
};

const useAllowedUser = (): boolean => {
  const {fuPaUser} = useSelector(reduxSelectCurrentUser);

  return useMemo(() => {
    return !!fuPaUser?.id;
  }, [fuPaUser]);
};

export default function useAllowSoftTicker(match: SofttickerMatch | undefined): boolean {
  if (!match) {
    return false;
  }
  const isAllowedUser = useAllowedUser();
  const isSoftTickerEligible = useIsSofttickerEligible(match);

  return isSoftTickerEligible && isAllowedUser;
}

function useIsSofttickerEventEligible(match: SofttickerMatch) {
  return useMemo(() => {
    const hasSoftticker = !!match.flags?.includes('softticker');
    const isReportable = checkIsReportableUntil(match.softtickerEventsReportableUntil);
    return isReportable && hasSoftticker;
  }, [match]);
}

export function useAllowSoftTickerEvents(match: SofttickerMatch): boolean {
  const isAllowedUser = useAllowedUser();
  const isSoftTickerEventEligible = useIsSofttickerEventEligible(match);

  return isAllowedUser && isSoftTickerEventEligible;
}

import {Dispatch, useContext} from 'react';
import {SoftTickerContext, SoftTickerDispatchContext} from 'app/contexts/SoftTickerContext';
import {SoftTickerData} from 'app/reducers/softTickerReducer';

interface ISoftTickerContext {
  softTickerData: SoftTickerData;
  dispatch: Dispatch<any>;
}

export const useSoftTickerContext = (): ISoftTickerContext => {
  const softTickerData = useContext(SoftTickerContext);
  const dispatch = useContext(SoftTickerDispatchContext);
  if (!softTickerData) {
    throw Error('useSoftTickerContext must be used inside SoftTickerContext.Provider');
  }
  if (!dispatch) {
    throw Error('useSoftTickerContext must be used inside SoftTickerDispatchContext.Provider');
  }
  return {softTickerData, dispatch};
};

export const goalType = {
  goal_shoot: '',
  goal_free_kick: 'direkter Freistoß',
  goal_freekick: 'direkter Freistoß',
  goal_headed: 'Kopfball',
  goal_distance_shoot: 'Distanzschuss',
  goal_scrounger: 'Abstauber',
  penalty_foul: 'Foulelfmeter',
  penalty_hand: 'Handelfmeter',
  penalty_shootout: 'Elfmeterschießen',
  goal_own_goal: 'Eigentor',
} as const;

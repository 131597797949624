import {format, formatRelative, parseISO} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import {captureSentryException} from 'app/services/sentryLogging';

const locale = 'de-DE';

/**
 * Converts ISO datestring into date-object of local timezone
 *
 * @param {string} isoDateString a string in the format `YYYY-MM-DD` (without time and timezone!)
 * @returns {Date} a date-object in the local timezone
 */
const isoDateStringToLocalDate = isoDateString => {
  const [year, month, day] = isoDateString.split('-');
  const date = new Date();
  // Reset to 01.01.XXXX to be sure every day an month can be set without injecting a bug:
  // Date is 30.09.2021 -> date.setMonth(1) [febuary] -> Date is: 02.03.2021 -> febuary has only 28-29 days but would set to 30.02.2021
  date.setMonth(0);
  date.setDate(1);
  // Order of the following lines is important!
  // We start with the current date (01.09.2021) and overwrite each of the date elements to set the birthdate (31.03.1999)
  // Test: start with overwriting the day: date.setDate('31') -> 01.10.2021 is the result as 31.09.2021 is not possible
  // To fix this we need to first set year and month before we set the date,
  // because only by doing this we can be sure, that the year and month "supports" the given day
  date.setFullYear(year);
  date.setMonth(month - 1); // month starts at 0 = january
  date.setDate(day);

  return date;
};

const formatLabelWithTime = (label, time) => (!time ? label : label + ", HH:mm 'Uhr'");

const formatRelativeDate = (date, stringFormat, time) => {
  const formatRelativeLocale = {
    lastWeek: formatLabelWithTime(stringFormat, time),
    yesterday: formatLabelWithTime("'Gestern'", time),
    today: formatLabelWithTime("'Heute'", time),
    tomorrow: formatLabelWithTime("'Morgen'", time),
    nextWeek: formatLabelWithTime(stringFormat, time),
    other: formatLabelWithTime(stringFormat, time),
  };

  const realDate = typeof date === 'string' ? parseISO(date) : date;
  const baseDate = new Date();
  const locale = {
    ...deLocale,
    formatRelative: token => formatRelativeLocale[token],
  };

  return formatRelative(realDate, baseDate, {locale});
};

const formatBirthDate = dateString => {
  const date = isoDateStringToLocalDate(dateString);
  let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
  return date.toLocaleString(locale, options);
};

const formatShortDate = date => {
  let formatedDate = new Date(date);
  let options = {year: 'numeric', month: '2-digit', day: '2-digit'};

  return formatedDate.toLocaleString(locale, options);
};

const formatMediumDate = date => {
  let formatedDate = new Date(date);
  let options = {year: 'numeric', month: 'long', day: 'numeric'};

  return formatedDate.toLocaleString(locale, options);
};

const formatLongDate = date => {
  let formatedDate = new Date(date);
  let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

  return formatedDate.toLocaleString(locale, options);
};

const getRelativeDate = (date, options) => {
  const DAY_IN_MS = 86400000;

  const today = new Date();
  const tomorrow = new Date(today.valueOf() + DAY_IN_MS);

  const todayLabel = today.toLocaleString(locale, options);
  const tomorrowLabel = tomorrow.toLocaleString(locale, options);
  const originDate = date.toLocaleString(locale, options);

  if (originDate === todayLabel) {
    return 'Heute';
  } else if (originDate === tomorrowLabel) {
    return 'Morgen';
  } else {
    return originDate;
  }
};

const formatDateWithWeekday = (date, showTime) => {
  return formatRelativeDate(date, 'E, dd.MM.yyyy', showTime);
};

const formatDayMonthDate = date => {
  let formatedDate = new Date(date);
  let options = {month: '2-digit', day: '2-digit'};

  return formatedDate.toLocaleString(locale, options);
};

const formatDay = date => {
  let formatedDate = new Date(date);
  return format(formatedDate, 'dd');
};
const formatMonth = date => {
  let formatedDate = new Date(date);
  return format(formatedDate, 'MMM', {locale: deLocale});
};

const formatDate = (date, formatString) => {
  return format(parseISO(date), formatString, {locale: deLocale});
};

// example Dezember 2022
const formatMonthYear = date => {
  return formatDate(date, 'MMMM yyyy');
};

const formatTime = date => {
  let formatedDate = new Date(date);
  let options = {hour: 'numeric', minute: 'numeric'};

  return formatedDate.toLocaleString(locale, options);
};

const getTimePeriodAsString = (date, baseDate = new Date()) => {
  if (!(date instanceof Date) || !(baseDate instanceof Date)) {
    captureSentryException(new Error('Non-Date object passed to getTimePeriodAsString'), {extra: {date, baseDate}});
    return null;
  }
  const baseDateInMs = baseDate.getTime();
  const dateInMs = date.getTime();
  const difference = dateInMs - baseDateInMs;
  if (difference < 0) {
    return null;
  }
  const MS_PER_MINUTE = 1000 * 60;
  const MS_PER_HOUR = MS_PER_MINUTE * 60;
  const MS_PER_DAY = MS_PER_HOUR * 24;
  const days = Math.floor(difference / MS_PER_DAY);
  if (days === 1) {
    return `einem Tag`;
  }
  if (days > 1) {
    return `${days} Tagen`;
  }
  const hours = Math.floor(difference / MS_PER_HOUR);
  if (hours === 1) {
    return `einer Stunde`;
  }
  if (hours > 1) {
    return `${hours} Stunden`;
  }
  const minutes = Math.floor(difference / MS_PER_MINUTE);
  if (minutes === 1) {
    return `einer Minute`;
  }
  if (minutes < 1) {
    return 'weniger als einer Minute';
  }
  return `${minutes} Minuten`;
};

export {
  formatBirthDate,
  formatShortDate,
  formatMediumDate,
  formatLongDate,
  formatDateWithWeekday,
  formatDayMonthDate,
  formatDate,
  formatTime,
  getRelativeDate,
  formatMonthYear,
  getTimePeriodAsString,
  formatDay,
  formatMonth,
};

import React, {useMemo} from 'react';
import {
  Content,
  Container,
  DataContainer,
  ImageContainer,
  GoalInformation,
  Text,
} from 'app/components/matchEvents/MatchEventStyles';
import {COLORS, ELEVATIONS, SIZES, Image, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {LinkOnComponent} from 'app/components/links/link';
import {Person, PlayArrow} from '@mui/icons-material';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';
import EditScorerButton from 'app/components/match/liveResult/updateScorer/EditScorerButton';
import useAllowSoftTicker from 'app/hooks/useAllowSoftTicker';
import {goalType} from 'app/helpers/goalType.const';
import {captureSentryException} from 'app/services/sentryLogging';
import MatchEventMinute from 'app/components/matchEvents/MatchEventMinute';
import MatchResult from 'app/components/matchRow/MatchResult';
import {MatchResultModel} from 'app/models/match/MatchResultModel';

const SCMatchEventMinute = styled(MatchEventMinute)`
  align-self: flex-end;
`;

const SCContainer = styled(Container)`
  flex-direction: column;
`;

const VideoContainer = styled.div`
  margin-top: ${SIZES['16']};
  position: relative;
  aspect-ratio: 16/ 9;
  width: 100%;
  cursor: pointer;
`;

const IconContainer = styled(FlexContainer)`
  position: absolute;
  width: ${SIZES['28']};
  height: ${SIZES['28']};
  border-radius: 100%;
  background-color: ${COLORS.black};
  opacity: 0.7;
  box-shadow: ${ELEVATIONS.PlayButton};
  // center icon
  align-items: center;
  justify-content: center;
  // center in parent
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  svg {
    font-size: ${SIZES['16']};
  }

  ${props => props.theme.desktopLayout`
    width: ${SIZES['64']};
    height: ${SIZES['64']};

    svg {
      font-size: ${SIZES['32']};
    }
  `}
`;

const VideoImageContainer = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  cursor: pointer;
`;

const MatchResultRightAligned = styled(MatchResult)`
  &&& {
    margin-left: auto;
  }
`;

const EventHeadline = styled(FlexContainer)`
  align-items: center;
  padding-bottom: ${SIZES['8']};
  gap: ${SIZES['6']};
`;
const getTeamForSofttickerEdit = (teamSlug, homeTeamSlug, awayTeamSlug) => {
  if (!teamSlug) {
    captureSentryException(new Error('No teamSlug provided in matchEvent goal'));
    return '';
  }
  if (teamSlug === homeTeamSlug) {
    return 'homeTeam';
  }
  if (teamSlug === awayTeamSlug) {
    return 'awayTeam';
  }
  return '';
};

const MatchEventGoal = ({event, hideScore, match}) => {
  const matchResult = useMemo(() => (event ? new MatchResultModel(event) : null), [match]);
  const highlightColor = COLORS.fupaSecondary;
  const isDeactivated = event.primaryRole?.player?.isDeactivated;
  const allowLiveResult = useAllowSoftTicker(match);
  const editData = useMemo(() => {
    if (!allowLiveResult) {
      return null;
    }
    return {
      minute: event.minute,
      homeGoals: event.homeGoal,
      awayGoals: event.awayGoal,
      category: event.subtype,
      team: getTeamForSofttickerEdit(event.team?.slug, match.homeTeam?.slug, match.awayTeam?.slug),
      scorer: event.primaryRole?.player?.id ?? 'unbekannt',
      assistant: event.secondaryRole?.player?.id ?? 'unbekannt',
    };
  }, [event, allowLiveResult]);

  const goalTypeLabel = goalType[event.subtype];

  let standingMessage = 'Tor ⚽️';
  standingMessage += event.team ? ` für ${event.team.name.middle}` : '';
  const emblem = event.team?.image ? (
    <Image entity='emblem' src={event.team.image} ratio='1:1' width={20} height={20} fit={true} />
  ) : null;
  const time = event.additionalMinute > 0 ? `${event.minute}'+${event.additionalMinute}` : `${event.minute}'`;

  let playerImage = (
    <Person style={{background: COLORS.lightestGrey, borderRadius: '100%', color: COLORS.lightGrey, fontSize: 64}} />
  );
  let playerName = 'Unbekannt';
  let displayGoalType;
  let showCount;
  if (event.primaryRole) {
    const {player, seasonCount} = event.primaryRole;
    playerImage = player.image ? (
      <Image entity='player' key={player.slug} src={player.image} ratio='1:1' width={64} height={64} circle={true} />
    ) : (
      playerImage
    );
    playerName = player.firstName + ' ' + player.lastName;

    const showSeasonCount = seasonCount && event.subtype !== 'goal_own_goal' && event.subtype !== 'penalty_shootout';
    if (showSeasonCount) {
      showCount = `${seasonCount}. Saisontor${goalTypeLabel ? ' - ' : ''}`;
    }
  }

  displayGoalType = (
    <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
      {showCount}
      {goalTypeLabel}
    </ProfileTypography>
  );

  let assist, text;
  if (event.secondaryRole) {
    let count = event.secondaryRole.seasonCount ? `(${event.secondaryRole.seasonCount}. Saisonvorlage)` : null;
    assist = (
      <Typography variant='caption1'>
        {event.secondaryRole.player.firstName} {event.secondaryRole.player.lastName} {count}
      </Typography>
    );
  }
  if (event.text) {
    text = (
      <Text withPadding variant='body2'>
        {event.text}
      </Text>
    );
  }

  const showVideos = event.videos?.length
    ? event.videos.map(video => (
        <VideoContainer key={video.slug}>
          <LinkOnComponent to={`/tv/match/${video.matchPlaylist}/${video.slug}`}>
            <VideoImageContainer>
              <Image entity='video' actualWidthFactor={1} ratio='16:9' src={video.thumbnail} />
            </VideoImageContainer>
            <IconContainer>
              <PlayArrow style={{color: COLORS.white}} />
            </IconContainer>
          </LinkOnComponent>
        </VideoContainer>
      ))
    : null;

  const score = hideScore ? null : <MatchResultRightAligned matchResult={matchResult} allowLive={false} />;

  const goalContent = (
    <FlexContainer>
      <ImageContainer>{playerImage}</ImageContainer>
      <GoalInformation>
        <ProfileTypography className='name' isDeactivated={isDeactivated} variant='body1'>
          {playerName}
        </ProfileTypography>
        {displayGoalType}
        {assist}
      </GoalInformation>
    </FlexContainer>
  );

  const playerContainer =
    event.primaryRole && !isDeactivated ? (
      <LinkOnComponent to={`/player/${event.primaryRole.player.slug}`}>{goalContent}</LinkOnComponent>
    ) : (
      goalContent
    );

  return (
    <Content>
      <SCContainer>
        <FlexContainer style={{justifyContent: 'space-between'}}>
          <SCMatchEventMinute minute={time} customBgColor={highlightColor} />
          {allowLiveResult ? <EditScorerButton initData={editData} matchEventId={event.id} /> : null}
        </FlexContainer>
        <DataContainer style={{borderLeft: `1.5px solid ${highlightColor}`}}>
          <EventHeadline>
            <Typography variant={'subheading'} component='span'>
              {standingMessage}
            </Typography>
            {emblem}
            {score}
          </EventHeadline>
          {playerContainer}
          {text}
          {showVideos}
        </DataContainer>
      </SCContainer>
    </Content>
  );
};

export default MatchEventGoal;

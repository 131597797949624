import {SoftTickerAction, SoftTickerData, UpdateScorerData} from 'app/reducers/softTickerReducer';
import {CreateResultResponse, StoreResultResponse} from 'app/components/match/liveResult/types';

export function openUpdateScorer(data: UpdateScorerData): SoftTickerAction {
  return {
    type: 'OPEN_UPDATE_SCORER',
    data,
  };
}

export function openAddScorer(view: string): SoftTickerAction {
  return {
    type: 'OPEN_ADD_SCORER',
    data: view,
  };
}

export function handleResultSuccess(data: StoreResultResponse): SoftTickerAction {
  return {
    type: 'UPDATE_RESULT_SUCCESS',
    data,
  };
}

export function openResultForm(data: CreateResultResponse): SoftTickerAction {
  return {
    type: 'OPEN_RESULT_FORM',
    data,
  };
}

export function loadSoftTickerData(data: SoftTickerData): SoftTickerAction {
  return {
    type: 'LOAD_SOFT_TICKER_DATA',
    data,
  };
}

export function closeSoftTicker(): SoftTickerAction {
  return {
    type: 'CLOSE_SOFT_TICKER_DIALOG',
  };
}

import {createSelector} from 'reselect';

interface CurrentUser {
  initializing: boolean;
  user: any;
  claims: any;
  fuPaUser: any;
  fuPaUserFetching: boolean;
  follows: any[];
  followsFetched: boolean;
  followsError: any | null;
  newFollow: {
    askForPush: boolean;
    entity: any | null;
  };
  pushSupport: boolean;
  notificationPermission: string;
  notificationNoSupportBanner: number;
  messagingDeviceToken: string;
  devices: any[];
  devicesFetched: boolean;
  devicesError: any | null;
  ppid: string;
}

interface RootState {
  currentUser: CurrentUser;
}

export const reduxSelectCurrentUser = createSelector(
  [(state: RootState) => state.currentUser],
  currentUser => currentUser
);
